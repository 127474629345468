import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ReferenceLine, Tooltip } from 'recharts';
import AxisLabel from './AxisLabel'
import constants from '../constants'
import HumanReadableNumber from '../../../helpers/HumanReadableNumber'

@inject("rootStore") @observer
export default class Plotter extends Component {
  render() {
    const { plotterStore } = this.props.rootStore

    return (
      <ResponsiveContainer width="100%" aspect={2}>
        <LineChart data={plotterStore.plot}>
          <CartesianGrid stroke="#ddd" strokeDasharray="3 3" />
          <Tooltip
            labelFormatter={(xValue) => `${HumanReadableNumber.formatted(xValue, {minimumFractionDigits: 2})} MHz`}
            formatter={(yValue) => `${HumanReadableNumber.formatted(yValue, {minimumFractionDigits: 2})} dB` }
          />
          <ReferenceLine stroke="#00812e" yAxisId="left" />
          <XAxis
            label={<AxisLabel axisType="xAxis">Frequency (MHz)</AxisLabel>}
            dataKey="frequency"
            type="number"
            domain={constants.xAxisDomain}
            ticks={constants.xAxisTicks}
            scale="log"
            tickFormatter={n => constants.xAxisDisplay[n] || n}
          />
          <YAxis
            label={<AxisLabel axisType="yAxis">Insertion Loss (dB)</AxisLabel>}
            type="number"
            tickFormatter={(n) => HumanReadableNumber.formatted(n, {minimumFractionDigits: 2})}
            ticks={constants.yAxisTicks}
            domain={constants.yAxisDomain}
            scale="linear"
            yAxisId="left"
            orientation="left"
          />
          {plotterStore.plottedParts.map((part, index) => 
            <Line
              key={part.partNumber}
              type="monotone"
              dataKey={part.partNumber}
              strokeWidth={2}
              stroke={constants.colors[index]}
              dot={false}
              yAxisId="left"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    )
  }
}
