import { observable, computed, action } from 'mobx'
import Part from './Part'
import difference from 'lodash/difference'
import constants from '../constants'

export default class PlotterStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    this.frequencies = this._generate_frequencies()
    this.plotCounter = 0
  }

  @observable circuitMode = null
  @observable parts = []
  @observable _plottedParts = [null, null, null, null, null]


  @computed get plottedParts() {
    return this._plottedParts.filter(part => part)
  }

  @action deserializeParts(serializedRlcValues) {
    this.parts = serializedRlcValues.map(value => new Part({
      partNumber: value["part_number"],
      c1:         value["c1"],
      r1:         value["r1"],
      l1:         value["l1"],
      c2:         value["c2"],
      r2:         value["r2"],
      l2:         value["l2"]
    }))
  }

  @action resetCircuitMode() {
    this.circuitMode = null
  }

  @action addPlot(partNumber) {
    const part = this.availableParts.find(part => part.partNumber === partNumber)
    this._plottedParts[this.plotCounter++ % constants.maxPlots] = part
  }

  @action removePlot(partNumber) {
    const part = this.parts.find(part => part.partNumber === partNumber)
    this._plottedParts.remove(part)
  }

  @action resetAllPlots() {
    this._plottedParts = [null, null, null, null, null]
  }

  @computed get plot() {
    return this.frequencies.map(frequency => {
      let hash = {frequency: frequency}
      this.plottedParts.forEach(part => hash[part.partNumber] = part.s21AtFrequency(frequency, this.circuitMode))
      return hash
    })
  }

  @computed get availableParts() {
    return difference(this.parts.slice(), this.plottedParts.slice())
  }

  @computed get showCircuitSelector() {
    return this.circuitMode ? false : true
  }


  _generate_frequencies() {
    let frequencies = []
    for(let i = 0.01; i < 0.10; i += 0.001) {
      frequencies.push(i)
    }
    for(let i = 0.10; i < 1.00; i += 0.01) {
      frequencies.push(i)
    }
    for(let i = 1.00; i < 10.00; i += 0.1) {
      frequencies.push(i)
    }
    for(let i = 10.00; i < 100.00; i += 1) {
      frequencies.push(i)
    }
    for(let i = 100.00; i < 1000.00; i += 10) {
      frequencies.push(i)
    }
    for(let i = 1000.00; i <= 8500.00; i += 100) {
      frequencies.push(i)
    }
    return frequencies
  }
}
