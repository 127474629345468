import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Row, Col } from 'react-bootstrap'
import CircuitSelector from './CircuitSelector'
import Plotter from './Plotter'

@inject("rootStore") @observer
export default class Container extends Component {
  render() {
    const { plotterStore } = this.props.rootStore
    return (
      <div className="s21-container">
        <Grid fluid={true}>
          <Row>
            <Col xs={12}>
              { plotterStore.showCircuitSelector ?
                <CircuitSelector />
                :
                <Plotter />
              }
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}
