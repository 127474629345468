import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PlotterControl from './PlotterControl'
import PlotterChart from './PlotterChart'


@inject("rootStore") @observer
export default class Plotter extends Component {
  handleEdit = () => {
    const { plotterStore } = this.props.rootStore
    plotterStore.resetCircuitMode()
  }

  renderTitle() {
    const { plotterStore } = this.props.rootStore

    if(plotterStore.circuitMode === "emi-filtering") {
      return (
        <span>
          X2Y EMI filter - Common mode (S<span style={{fontSize: "0.6em"}}>CC21</span>)
        </span>
      )
    }
    else {
      return "X2Y power bypass – single ended (S21)"
    }
  }

  render() {

    return (
      <div>
        <PlotterControl />
        <h3 style={styles.subheader}>
          { this.renderTitle() }
        </h3>
        <PlotterChart />
        <div style={styles.disclaimer}>
          The above chart  is made of estimated values and it is provided for reference . The characteristic may vary to a certain extent depending on the substrate, mounting of the device, and configuration (50Ω system  or not).
        </div>
      </div>
    )
  }
}

const styles ={
  subheader: {
    textAlign: 'center',
    marginTop: 4
  },
  disclaimer: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 30,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  edit: {
    paddingLeft: 10,
    color: "#ddd",
    cursor: "pointer",
    fontSize: 22
  }
}
