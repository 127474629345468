const constants = {
  colors: [
    "#537CBA",
    "#98BC56",
    "#B9474A",
    "#871a97",
    "#46ac9c"
  ],
  xAxisTicks: [0.01, 0.1, 1, 10, 100, 1000, 10000],
  xAxisDisplay: {
    0.010: "0.010",
    0.100: "0.100",
    1: "1",
    10: "10",
    100: "100",
    1000: "1,000",
    10000: "10,000"
  },
  xAxisDomain: [0.01, 10000],
  yAxisTicks: [0, 0, -10, -20, -30, -40, -50, -60, -70, -80],
  yAxisDomain: [-80, 0],
  maxPlots: 5
}

export default constants
