// import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import S21Plotter from '../components/S21Plotter'

document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.getElementsByClassName('react-s21-plotter')
  for(let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const data = JSON.parse(node.getAttribute('data'))

    ReactDOM.render(<S21Plotter {...data} />, node)
  }
})
