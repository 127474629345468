import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import constants from '../constants'

@inject("rootStore") @observer
export default class PlotterControl extends Component {
  state = {
    partNumber: null
  }

  handleSubmit = (e) => {
    const { plotterStore } = this.props.rootStore
    const { partNumber } = this.state

    e.preventDefault()
    if(partNumber) {
      this.setState({partNumber: null})
      setTimeout(() => plotterStore.addPlot(partNumber), 0)
    }
  }

  handlePartNumberChange = (option) => {
    const partNumber = option ? option.value : null
    this.setState({ partNumber })
  }

  handlePartRemoval = (e, partNumber) => {
    const { plotterStore } = this.props.rootStore
    plotterStore.removePlot(partNumber)
  }

  handleReset = () => {
    const { plotterStore } = this.props.rootStore
    if(confirm("Are you sure you want to clear all plots?")) {
      plotterStore.resetAllPlots()
    }
  }

  render() {
    const { plotterStore } = this.props.rootStore
    const { partNumber } = this.state
    const selectOptions = plotterStore.availableParts.map(part => {
      return {label: part.partNumber, value: part.partNumber}
    })

    return (
      <Row>
        <Col sm={6}>
          <form onSubmit={this.handleSubmit}>
            <div style={styles.partFormWrapper}>
              <div style={styles.selectWrapper}>
                <Select
                  name="part-number"
                  value={partNumber}
                  onChange={this.handlePartNumberChange}
                  options={selectOptions}
                  placeholder="Enter sub part number (e.g., 500X07N4R7DV4T)"
                />
              </div>
              <div style={styles.submitWrapper}>
                <Button
                  bsStyle="primary"
                  bsSize="sm"
                  style={styles.submitBtn}
                  block={true}
                  type="submit"
                  disabled={!partNumber}
                  >
                  Add Part +
                </Button>
              </div>
            </div>
          </form>
        </Col>
        <Col sm={5} smOffset={1}>
          <div style={styles.partNumberWrapper}>
            <div style={styles.partNumberNote}>Up to { constants.maxPlots } plots shown simultaneously</div>
            <Row>
              { plotterStore.plottedParts.filter(part => part).map((part, index) =>
                <Col key={part.partNumber} md={6}>
                  <div style={{color: constants.colors[index]}} className="part-number">
                    { part.partNumber }
                    <a
                      onClick={(e) => this.handlePartRemoval(e, part.partNumber)}
                      >
                      <i className="fa fa-times" style={styles.removePartNumber} />
                    </a>
                  </div>
                </Col>
              )}
            </Row>
            { plotterStore.plottedParts.length > 0 &&
              <div style={styles.resetAllPlotsWrapper}>
                <a onClick={this.handleReset} style={styles.resetAllPlots}>Reset all Plots</a>
              </div>
            }
          </div>
        </Col>
      </Row>
    )
  }
}

const styles = {
  partFormWrapper: {
    display: "flex",
    flex: 1
  },
  selectWrapper: {
    flex: 1,
    padding: 10
  },
  submitWrapper: {
    padding: 10
  },
  submitBtn: {
    height: 36
  },
  partNumberWrapper: {
    padding: 10
  },
  partNumberNote: {
    fontStyle: "italic"
  },
  removePartNumber: {
    paddingLeft: 10,
    cursor: "pointer",
    color: "#999"
  },
  resetAllPlotsWrapper: {
    textAlign: 'center'
  },
  resetAllPlots: {
    cursor: "pointer"
  }
}
