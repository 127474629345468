import React, { Component } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { inject, observer } from 'mobx-react'
import emiFilteringImage from '../images/emi-filtering.png'
import powerBypassImage from '../images/power-bypass.png'

@inject("rootStore") @observer
export default class CircuitSelector extends Component {
  state = {
    circuitMode: null
  }

  handleCircuit1 = () => {
    this.setState({circuitMode: "emi-filtering"})
  }

  handleCircuit2 = () => {
    this.setState({circuitMode: "power-bypass"})
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { plotterStore } = this.props.rootStore
    plotterStore.circuitMode = this.state.circuitMode
  }

  render() {
    const { circuitMode } = this.state

    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Please select how the X2Y device is connected:</h3>

        <Row>
          <Col sm={6}>
            <div onClick={this.handleCircuit1} style={circuitMode === "emi-filtering" ? styles.selectorContainerActive : styles.selectorContainerInactive}>
              <Row>
                <Col sm={3} style={styles.radioContainer}>
                  <input type="radio" checked={circuitMode === "emi-filtering"} onChange={this.handleCircuit1} />
                </Col>
                <Col sm={9}>
                  <div>Differential Configuration</div>
                  <div>(EMI filtering)</div>
                  <div style={styles.imageContainer}>
                    <img src={emiFilteringImage} className="img-responsive" />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={6}>
            <div onClick={this.handleCircuit2} style={circuitMode === "power-bypass" ? styles.selectorContainerActive : styles.selectorContainerInactive}>
              <Row>
                <Col sm={3} style={styles.radioContainer}>
                  <input type="radio" checked={circuitMode === "power-bypass"} onChange={this.handleCircuit2} />
                </Col>
                <Col sm={9}>
                  <div>Single-Ended Configuration</div>
                  <div>(Power Bypass)</div>
                  <div style={styles.imageContainer}>
                    <img src={powerBypassImage} className="img-responsive" />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div style={styles.btnContainer}>
          <Button type="submit" bsStyle="primary" disabled={!circuitMode}>
            Next
            { " " }
            <i className="fa fa-angle-double-right" />
          </Button>
        </div>
      </form>
    )
  }
}

const styles = {
  radioContainer: {
    textAlign: "right"
  },
  selectorContainerInactive: {
    cursor: "pointer",
    padding: "10px 20px 30px 10px"
  },
  imageContainer: {
    marginTop: 15
  },
  selectorContainerActive: {
    cursor: "pointer",
    background: "#f7f7f7",
    border: "1px solid f0f0f0",
    padding: "10px 20px 30px 10px"
  },
  btnContainer: {
    padding: 30,
    textAlign: "right"
  }
}
