import React, { Component } from 'react'
import { Provider } from 'mobx-react'
import Container from './components/Container'
import RootStore from './stores/RootStore'

export default class S21Plotter extends Component {
  constructor(props) {
    super(props)

    this.rootStore = new RootStore()
    this.rootStore.plotterStore.deserializeParts(props["rlc_values"])
  }

  render() {
    return (
      <Provider rootStore={this.rootStore}>
        <Container />
      </Provider>
    )
  }
}
