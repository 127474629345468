import math from 'mathjs'
math.config({predictable: true})

export default class Part {
  constructor(args = {}) {
    this.partNumber = args.partNumber
    this.c1 = args.c1
    this.r1 = args.r1
    this.l1 = args.l1
    this.c2 = args.c2
    this.r2 = args.r2
    this.l2 = args.l2
  }

  s21AtFrequency(frequency, circuitMode) {
    const c = circuitMode === "emi-filtering" ? this.c1 : this.c2
    const r = circuitMode === "emi-filtering" ? this.r1 : this.r2
    const l = circuitMode === "emi-filtering" ? this.l1 : this.l2

    const result = math.eval(`-20 * log10( sqrt( 1 + ( 25 / sqrt( ( r/1000)^2 + ( 6.28318 * frequency * l / 10^6 - 1 / ( 6.28318 * frequency * c / 10^6))^2))^2))`, { frequency, c, r, l })

    return result
  }
}
